
import { defineComponent, ref } from 'vue'
// import Combocourse from '@/components/client/courses/Combocourse.vue'
import Listofcourse from '@/components/client/courses/Listofcourse.vue'

import {
  HomeOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    // Combocourse,
    Listofcourse
  },
  setup () {
    const activeKey = ref('1')
    const next = async () => {
      let number = +activeKey.value
      number++
      activeKey.value = number.toString()
    }
    const prev = async () => {
      let number = +activeKey.value
      number--
      activeKey.value = number.toString()
    }
    return {
      activeKey,
      next,
      prev
    }
  }
})
