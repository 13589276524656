<template>
  <div class="main">
    <div class="courses-List content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Courses</a-breadcrumb-item>
      </a-breadcrumb>
      <a-row :gutter="24" class="tableList">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="tabsection">
            <Listofcourse />
            <!--<a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="1" tab="List of Courses">
                <Listofcourse />
              </a-tab-pane>
              <a-tab-pane key="2" tab="Combo Courses" force-render>
                <Combocourse />
              </a-tab-pane>
            </a-tabs>-->
            <div class="steps-action">
              <a-button type="danger" shape="circle" size="large" v-if="activeKey != 1" @click="prev">
                <ArrowLeftOutlined />
              </a-button>
              <a-button type="danger" shape="circle" size="large" v-if="activeKey != 2" style="margin-left: 8px" @click="next">
                <ArrowRightOutlined />
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <div></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
// import Combocourse from '@/components/client/courses/Combocourse.vue'
import Listofcourse from '@/components/client/courses/Listofcourse.vue'

import {
  HomeOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    // Combocourse,
    Listofcourse
  },
  setup () {
    const activeKey = ref('1')
    const next = async () => {
      let number = +activeKey.value
      number++
      activeKey.value = number.toString()
    }
    const prev = async () => {
      let number = +activeKey.value
      number--
      activeKey.value = number.toString()
    }
    return {
      activeKey,
      next,
      prev
    }
  }
})
</script>

<style lang="scss">
.courses-List {
  .tableList {
    .tabsection {
      padding: 15px 0 0 0;
      width: 100%;
      .ant-tabs-bar {
        margin: 0 0 10px 0;
        border-bottom: 0;
        .ant-tabs-ink-bar-animated {
          display: none !important;
        }
        .ant-tabs-nav .ant-tabs-tab {
          border-radius: 8px;
          padding: 12px 16px;
          width: -webkit-fill-available;
          background-color: #d2f8ff;
          font-family: "TT Norms Pro Medium";
          font-size: 20px;
          color: #646464;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
          background-color: #11cdef;
          color: #fff !important;
        }
        .ant-tabs-nav .ant-tabs-tab:active,
        .ant-tabs-nav .ant-tabs-tab:hover {
          color: #11cdef;
        }
        .ant-tabs-nav-wrap {
          margin-bottom: 0px;
        }
        .ant-tabs-nav {
          width: 100%;
          & > div {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .steps-action {
      background: #ffffff;
      padding: 0 0 15px 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .ant-btn-danger {
        background-color: #ff7875;
        border-color: #ff7875;
      }
    }
  }
}
</style>
